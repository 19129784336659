.login-container h1 {
  font-weight: bold;
  margin: 0;
  text-align: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  max-width: 380px;
  padding: 20px;
  width: 100%;
}

.color-accent {
  color: #514eff;
}

.login-container h2 {
  text-align: center;
}

.login-container .para {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-align: center;
}

.forgot-password {
  font-size: 12px !important;
  margin-left: auto;
  color: #514eff;
  text-decoration: underline;
}

.login-container a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.fpwd {
  text-decoration: underline 1px solid #b2b1b9;
}

.login-container {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
}

.form {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 1s cubic-bezier(0.68, 0, 0.265, 1.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.login-container.right-panel-active .sign-in-container {
  transform: translateX(100%);
  opacity: 0;
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.login-container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
}

.overlap-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 1s cubic-bezier(0.68, 0, 0.265, 1.2);
  background: #7c4eff;
  background: linear-gradient(0deg, #7c4eff, #514eff);
  z-index: 100;
}

.login-bg {
  object-fit: cover;
  height: 100vh;
  width: 100vw;
  transform: translateX(-50%);
  transition: transform 1s cubic-bezier(0.68, 0, 0.265, 1.2);
  position: absolute;
}

.login-container.right-panel-active .overlap-container {
  transform: translateX(-100%);
}

.login-container.right-panel-active .login-bg {
  transform: translateX(0%);
}

.login-container.right-panel-active .overlap-panels {
  transform: translateX(50%);
  /* background-image: linear-gradient(#001e6c, #7dedff); */
}

.overlap-panels {
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 1s cubic-bezier(0.68, 0, 0.265, 1.2);
}

.overlap-panel {
  position: absolute;
  display: flex;
  padding: 0 40px;
  text-align: center;
  top: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 1s cubic-bezier(0.68, 0, 0.265, 1.2);
}

.login-logo-white {
  position: absolute;
  bottom: 50px;
  height: 40px;
}

.overlap-left {
  transform: translateX(-20%);
}

.login-container.right-panel-active .overlap-left {
  transform: translateX(0);
}

.overlap-right {
  right: 0;
  transform: translateX(0);
}

.login-container.right-panel-active .overlap-right {
  transform: translateX(20%);
}

@media only screen and (max-width: 700px) {
  .sign-in-container {
    width: 100%;
  }
  .sign-up-container {
    width: 100%;
  }
  .overlap-container {
    width: 100%;
  }
  .overlap-panel {
    width: 0;
  }
  .login-container.right-panel-active .sign-in-container {
    transform: translateX(0%);
  }
  .login-container.right-panel-active .sign-up-container {
    transform: translateX(0%);
  }
  .overlap-container {
    display: none;
  }
}
