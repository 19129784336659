@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

.lucide {
  stroke-width: 1.5px;
  height: 20px;
  width: 20px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  scroll-behavior: smooth;
}

body {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.MuiFormHelperText-root {
  font-size: 13px !important;
}

.MuiDataGrid-root {
  border-width: 0px !important;
  padding-bottom: 0px !important;
}

.equal-columns {
  display: flex;
  gap: 10px;
}

.equal-columns > * {
  flex: 1;
}

.no-perm-wrapper {
  cursor: not-allowed;
  display: flex;
  flex-direction: column;
}

.no-perm {
  filter: opacity(30%);
  cursor: not-allowed;
  pointer-events: none;
}

.no-perm * {
  cursor: not-allowed;
  pointer-events: none;
}
